// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Table of Contents
//
// Color system
$theme-colors: (
  primary: $navy,
  secondary: $teal,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800
);

// Options
$enable-rounded: true;

// Spacing
// Body
// Links
// Grid breakpoints

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1236px
) !default;
@include _assert-ascending($container-max-widths, "$container-max-widths");



// Grid columns
// Fonts
// Components
// Tables
// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

/*$input-btn-padding-y: .5rem !default;
$input-btn-padding-x: .75rem !default;
$input-btn-line-height: 1.25 !default;

$input-btn-padding-y-sm: .25rem !default;
$input-btn-padding-x-sm: .5rem !default;
$input-btn-line-height-sm: 1.5 !default;

$input-btn-padding-y-lg: .5rem !default;
$input-btn-padding-x-lg: 1rem !default;
$input-btn-line-height-lg: 1.5 !default;

$btn-font-weight: $font-weight-normal !default;
$btn-box-shadow: inset 0 1px 0 rgba($white,.15), 0 1px 1px rgba($black,.075) !default;
$btn-focus-box-shadow: 0 0 0 3px rgba(theme-color("primary"), .25) !default;
$btn-active-box-shadow: inset 0 3px 5px rgba($black,.125) !default;

$btn-link-disabled-color: $gray-600 !default;

$btn-block-spacing-y: .5rem !default;

// Allows for customizing button radius independently from global border radius

$btn-transition: all .15s ease-in-out !default;*/
$btn-border-radius: 5px;
$btn-border-radius-lg: 5px;
$btn-border-radius-sm: 5px;


// Forms
// Dropdowns
// Z-index master list
// Navs
// Navbar
// Pagination
// Jumbotron
// Form states and alerts
// Cards
// Tooltips
// Popovers
// Badges
// Modals
// Alerts
// Progress bars
// List group
// Image thumbnails
// Figures
// Breadcrumbs
// Carousel
// Close
// Code

